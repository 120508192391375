/**
 * PageLayout is a page wrapper providing a specified or default header
 * and a footer.
 * Supported props: config, ctx, currentpath, header, theme
 */

import React from 'react';
import Nav from './NavToggle';
import Footer from './Footer';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function PageLayout(props) {
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.sections);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };
  const mainstyle = (props.theme ? {className: props.theme} : {});
  const navProps = {
    toplinks,
    sitename: cfg.sitename,
    theme: ((cfg.navbar && cfg.navbar.theme) ? cfg.navbar.theme : 'px-20'),
    ctx: props.ctx,
    currentpath: props.currentpath,
    localizerfn: localized
  };
  const pageHeader = props.header || <Nav {...navProps} />;

  return (
    <div id="page">
      <div id="layout-root">
        <header>{pageHeader}</header>
        <main {...mainstyle}>{props.children}</main>
        <Footer src={footdata} localizerfn={localized} />
      </div>
    </div>
  );
};
