/**
 * NavToggle produces a nav container with a button to toggle the display
 * of the links.
 * Supported props: toplinks, sitename, theme, ctx, currentpath, localizerfn
 */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import LinkMenu from './LinkMenu';
import LocaleSwitcher from './LocaleSwitcherModal';
import { css } from '../util/pagetools';

export default function Nav(props) {
  const localized = props.localizerfn;
  const localePicker = (props.ctx ? <LocaleSwitcher ctx={props.ctx} currentpath={props.currentpath} /> :
    <div></div>);
  const [open, setOpen] = useState(false);
  const navLinks = <LinkMenu links={props.toplinks} listStyle={`nav-list`} itemStyle={`nav-item`} localizerfn={localized} />;

  // As workaround for bootstrap, hide an element given a min-height of 1px by making bg transparent
  const navLinkContainer = css('row navbar-links', (open?'open':null))

  function openMenu(e) {
    const isExpanded = !open;
    let targetElem = e.target;
    if (targetElem.tagName!=='BUTTON') {
      targetElem = targetElem.closest('BUTTON');
    }
    if (isExpanded) targetElem.classList.add('open');
    else            targetElem.classList.remove('open');
    setOpen(!open);
  }

  return (
    <nav id="navtogglemodal" className={props.theme}>
      <div className="flx pt-8">
        <div id="logo" className="pl-15">
          <Link to={localized('/')} title={props.sitename}><h1>{props.sitename}</h1></Link>
        </div>
        <div className="flx-end flx-algn-ctr fg-1">
          {localePicker}
          <button className="navbar-btn" onClick={openMenu}>
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar top"></span>
            <span className="icon-bar middle"></span>
            <span className="icon-bar bottom"></span>
          </button>
        </div>
      </div>
      <div id="navbar-narrow" className={navLinkContainer}>{open && navLinks}</div>
    </nav>
  );
};
